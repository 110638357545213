<template>
  <div class="my-15">
    <section class="container py-15">
      <h1 class="descubre__title">
        {{ lenguages[idioma].cotizacion.titulo }}
      </h1>

      <hr class="mt-3 mb-5" />

      <div id="form">
        <h3>{{ lenguages[idioma].cotizacion.email }}:</h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="Déjanos tu correo para comunicarnos contigo"
          v-model="email"
        ></v-text-field>
        <h3>Phono:</h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="Déjanos tu número para contactarte"
          v-model="phone"
        ></v-text-field>
        <h3>{{ lenguages[idioma].cotizacion.destinoOExperiencia }}:</h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="Cuéntanos el destino de tu experiencia ideal"
          v-model="destino"
        ></v-text-field>
        <h3>
          {{ lenguages[idioma].cotizacion.tipoDeAeronave }}:
        </h3>
        <v-radio-group v-model="aeronave">
          <div class="d-flex flex-row align-center">
            <v-radio value="helicoptero" />
            <img
              :src="icons.purple.helicopter.straight"
              alt="Icono helicoptero"
              width="30"
            />
            <span class="mx-3">{{ lenguages[idioma].cotizacion.helicoptero }} </span>
            <v-radio value="avion" /> <span>{{ lenguages[idioma].cotizacion.avion }}</span>
            <img
              :src="icons.purple.plane"
              class="ml-2"
              alt="Icono helicoptero"
              width="35"
            />
          </div>
        </v-radio-group>

        <h3>
         {{ lenguages[idioma].cotizacion.cantidadDePasajeros }}:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="¿Cuántos pasajeros serían en este vuelo?"
          v-model="pasajeros"
          type="number"
        ></v-text-field>

        <h3>
          {{ lenguages[idioma].cotizacion.fechaAproximada }}
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="¿Cuántos pasajeros serían en este vuelo?"
          v-model="fecha"
          type="date"
        ></v-text-field>

        <h3>
          {{ lenguages[idioma].cotizacion.algoMas }}
        </h3>
        <v-textarea
          outlined
          :color="colors.mainPurple"
          placeholder="Ingrese aquí su mensaje"
          v-model="mensaje"
        ></v-textarea>
        <div @click="send" class="d-flex">
          <Boton :texto=" lenguages[idioma].cotizacion.enviar" class="btn" />
          <v-progress-circular
            indeterminate
            color="#645dc7"
            class="ml-3"
            v-show="loading"
          ></v-progress-circular>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import axios from "axios";
import firebase from "firebase";
const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default {
  name: "Cotizacion",
  components: {
    Boton,
  },
  data() {
    return {
      email: "",
      mensaje: "",
      loading: false,
      aeronaves: ["Avión", "Helicóptero"],
      aeronave: "",
      fecha: "",
      phone: "",
      pasajeros: "",
      destino: "",
      icono:
        "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fgreen%2FArrow%201.svg?alt=media&token=89183daf-5c7d-4b9f-8ce1-9cd6726699a9",
    };
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        const {
          aeronave,
          email,
          mensaje,
          destino,
          fecha,
          phone,
          pasajeros,
        } = this;
        if (
          !destino ||
          !email ||
          !mensaje ||
          !aeronave ||
          !fecha ||
          !phone ||
          !pasajeros
        )
          throw { message: "Debe llenar todos los campos" };
        if (!expEmail.test(email))
          throw { message: "El formato del correo electrónico es incorrecto" };
        await axios.post(
          "https://us-central1-hummingbird-453db.cloudfunctions.net/mailing/cotizacion",
          {
            aeronave,
            email,
            mensaje,
            destino,
            fecha,
            phone,
            pasajeros,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          }
        );

        this.$toast.open({
          message: "¡Mensaje enviado!",
          position: "bottom",
          duration: 3000,
        });

        this.aeronave = "";
        this.email = "";
        this.mensaje = "";
        this.destino = "";
        this.fecha = "";
        this.phone = "";
        this.pasajeros = "";
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message, {
          position: "bottom",
          duration: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.btn {
  width: 20%;
  height: 45px;
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
  }
}
</style>
