<template>
  <v-container class="errorPage text-center mx-auto mt-10">
    <h1>La ruta a la que intentas acceder no existe o no está disponible :(</h1>
    <v-divider class="my-5"></v-divider>
    <h2>Puedes seguir navegando viendo nuestros</h2>
    <div class="d-flex justify-space-around align-center">
      <router-link :to="{ name: 'Vuelos' }">
        <img width="75" :src="icons.purple.vuelos" alt="Vuelos icono" />
        <h3>Vuelos</h3>
      </router-link>

      <router-link :to="{ name: 'Experiencias' }">
        <img width="75" :src="icons.purple.experiencias" alt="Experiencias icono" />
       <h3> Experiencias</h3>
        </router-link
      >
    </div>
    <h2 class="pt-6">O visita nuestro <router-link to="/">Home</router-link> </h2>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Error",
  created() {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["icons"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.errorPage {
  padding-top: 100px;
}

a{
  color: $main-purple;
  text-decoration: underline;
}
</style>