<template>
  <div id="Contacto" class="py-15">
    <section class="container py-15">
      <h1 class="descubre__title">¿Quieres unirte a HummingBird?</h1>
      <h4 class="descubre__subtitle d-none d-sm-block mb-7">
        Llena el siguiente formulario
      </h4>

      <div id="form">
        <h3>RUT empresa</h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.rut"
        ></v-text-field>
        <h3>
          Nombre de la empresa:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.nombre"
        ></v-text-field>

        <h3>
          Nombre de fantasía de la empresa:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.nombreFantasia"
        ></v-text-field>

        <h3>
          Dirección comercial:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.direccion"
        ></v-text-field>

        <h3>
          Comuna:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.comuna"
        ></v-text-field>

        <h3>
          Región:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.region"
        ></v-text-field>

        <h3>
          Nombre del representante legal:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.nombreRepresentante"
        ></v-text-field>

        <h3>
          RUT del representante legal de la empresa:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.rutRepresentante"
        ></v-text-field>

        <h2>Datos de contacto</h2>
        <hr class="mb-3" />
        <h3>
          ¿Quién realiza la solicitud?:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.contacto.nombre"
        ></v-text-field>

        <h3>
          Puesto o función:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.contacto.puesto"
        ></v-text-field>

        <h3>
          Email:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.contacto.email"
        ></v-text-field>

        <h3>
          Teléfono 1:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.contacto.telefono1"
        ></v-text-field>

        <h3>
          Teléfono 2:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.contacto.telefono2"
        ></v-text-field>

        <h3>
          Otros datos de contacto:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.contacto.otros"
        ></v-text-field>

        <h2>Datos Bancarios</h2>
        <hr class="mb-3" />
        <h3>
          Banco:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.nombre"
        ></v-text-field>

        <h3>
          Número de cuenta:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.cuenta"
        ></v-text-field>

        <h3>
          Tipo de cuenta:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.tipo"
        ></v-text-field>

        <h3>
          Titular de la cuenta:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.titular"
        ></v-text-field>

        <h3>
          RUT del titular de la cuenta:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.rutTitular"
        ></v-text-field>

        <h3>
          Correo electrónico para comprobantes:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.email"
        ></v-text-field>

        <h3>
          Tipo de documento tributario de venta:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.banco.tipoDocumento"
        ></v-text-field>

        <h2>Datos Operacionales</h2>
        <hr class="mb-3" />
        <h3>
          N° AOC:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.datosOperacionales.naoc"
        ></v-text-field>

        <h3>
          Adjuntar certificado AOC:
        </h3>
        <v-file-input
          accept="image/*"
          label="File input"
          v-model="operador.datosOperacionales.aocDocument"
        ></v-file-input>

        <h3>
          Otras certificaciones (opcional):
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="Ejemplo: ISO9001, ISO14001, ISO 45001, Fase del SMS, BARS, etc"
          v-model="operador.datosOperacionales.otros"
        ></v-text-field>

        <h3>
          Dirección base de operaciones:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="Ejemplo: ISO9001, ISO14001, ISO 45001, Fase del SMS, BARS, etc"
          v-model="operador.datosOperacionales.direccion"
        ></v-text-field>

        <h3>
          Comuna base de operaciones:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.datosOperacionales.comuna"
        ></v-text-field>

        <h3>
          Región base de operaciones:
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="operador.datosOperacionales.region"
        ></v-text-field>

        <div @click="send" class="d-flex">
          <Boton texto="Enviar" class="btn" />
          <v-progress-circular
            indeterminate
            color="#645dc7"
            class="ml-3"
            v-show="loading"
          ></v-progress-circular>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
#Contacto {
  background: #fcfcfc;
}
.btn {
  width: 20%;
  height: 45px;
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
  }
}
</style>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import axios from "axios";

const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default {
  name: "Contacto",
  data() {
    return {
      nombre: "",
      email: "",
      mensaje: "",
      loading: false,
      operador: { contacto: {}, banco: {}, datosOperacionales: {} },
    };
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        const { nombre, email, mensaje } = this;
        if (!nombre || !email || !mensaje)
          throw { message: "Debe llenar todos los campos" };
        if (!expEmail.test(email))
          throw { message: "El formato del correo electrónico es incorrecto" };
        await axios.post(
          "https://us-central1-hummingbird-453db.cloudfunctions.net/mailing/contacto",
          { nombre, email, mensaje }
        );

        this.$toast.open({
          message: "¡Mensaje enviado!",
          position: "bottom",
          duration: 3000,
        });

        this.nombre = "";
        this.email = "";
        this.mensaje = "";
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message, {
          position: "bottom",
          duration: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    Boton,
  },
  computed: {
    ...mapState(["colors"]),
  },
};
</script>
