<template>
  <div id="Noticias" class="mt-15">
    <v-container class="mt-15">
      <h1 class="mt-15 mb-2">Noticias</h1>
      <hr class="mb-5" />

      <h4>Fecha: 02 / 20 / 2021</h4>
      <h2 class="mb-4">
        Hummingbird, la primera plataforma de vuelos compartidos de Chile.
      </h2>
      <v-row no-gutters class="pb-5 ">
        <v-col cols="12 pr-md-10 " sm="8">
          <p>
            ¿Sabías que hoy puedes vivir una experiencia en avión biplaza, avión
            mediano o helicóptero a tan sólo un click?
          </p>
          <p>
            Vive nuevas y emocionantes experiencias desde el cielo con
            Hummingbird, una plataforma que reúne toda la oferta en este tipo de
            aeronaves y te permite reservar en línea un cupo en vuelos
            compartidos.
          </p>
          <p>
            En la era de la transformación digital, este mercado no puede
            quedarse atrás. Bajo el slogan “Bringing the sky closer”, nuestra
            motivación es abrir las alas y encender los motores de la aviación
            privada.
          </p>
          <p>
            Hummingbird te brinda la posibilidad de sobrevolar Santiago y sus
            hermosos alrededores, traslado entre ciudades y experiencias full
            day en viñas o centros de ski para la temporada de invierno.
          </p>
          <p>
            Este año, debutamos con experiencias en el Día de San Valentín y
            lanzaremos nuestra app durante el primer semestre de 2021. La
            Seguridad Operacional es uno de nuestros pilares más importantes,
            manteniendo una rigurosa revisión de estándares y certificaciones de
            nuestros partners operadores, acorde a la regulación aeronáutica.
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="d-flex justify-center align-start img-noticias"
        >
        </v-col>
      </v-row>
      <v-row no-gutters class="pb-md-5 pb-15 ">
        <v-col
          cols="12 pb-md-0 mb-5"
          sm="4"
          class="d-flex justify-center align-start img-noticias"
        >
        </v-col>
        <v-col cols="12 pl-md-10 " sm="8">
          <p>
            Por mucho tiempo, el mercado de aeronaves pequeñas fue algo
            desconocido, muy exclusivo y de alto costo. Hummingbird llega para
            revolucionar este mercado y digitalizar una economía colaborativa
            que facilita el acceso a la aviación, permitiendo a nuestros
            usuarios optimizar no sólo su costos, sino también sus tiempos de
            traslado, trámites aeroportuarios, demoras por tráfico del
            transporte terrestre y minimización del riesgo de contagio de
            Covid-19.
          </p>
          <p>
            Para los operadores de aeronaves, Hummingbird es la externalización
            de ventas en transporte de pasajeros para las firmas operadoras,
            permitiéndole a nuestro país retomar el turismo, con nuevas y
            novedosas alternativas.
          </p>
          <p>
            Nuestros fundadores, provenientes de Chile, Venezuela y Francia,
            proyectan expandir sus fronteras y llegar a otros mercados
            internacionales, con una oferta competitiva y diferenciadora,
            representando la modernización del mercado de aeronaves pequeñas.
          </p>
        </v-col>
      </v-row>
      <hr />
      <NewsLetter  class="mb-15 mb-sm-0"/>
    </v-container>
  </div>
</template>

<script>
import NewsLetter from "@/components/Noticias/NewsLetter";

export default {
  name:"Noticias",
  components:{
    NewsLetter
  }
}
</script>

<style scoped>
.img-noticias {
  background-size: cover;
  background-position: bottom;
}

.img-noticias:nth-child(2) {
  background: url("https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2FimagenesDestinos%2FimagenesDestinosComprimidas%2FAsomado%20por%20la%20ventana_1200x1200.jpg?alt=media&token=96b014a7-cad5-46ee-836d-f37bbe457e1f");
  background-size: cover;
  background-position: center;
}
.img-noticias:nth-child(1) {
  background: url("https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2FimagenesDestinos%2FimagenesDestinosComprimidas%2Fcoordillera%20_1920x1920.jpg?alt=media&token=3c571137-bef5-4e4e-bf3c-b81754ae842b");
  background-size: cover;
  background-position: center;
}

@media (max-width: 600px) {
  .img-noticias {
    height: 500px;
  }
}
</style>
