<template>
  <section id="FAQ" class="faq mt-6">
    <v-container class="mx-auto faq__wrapper faq__terminosYCondiciones pa-10 ">
      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta1.titulo }}
        </h2>
        <p
          v-for="(parrafo, i) in lenguages[idioma].faqs.pregunta1.parrafos"
          :key="i"
        >
          {{ parrafo }}
        </p>
      </div>

     

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta4.titulo }}
        </h2>
        <p
          v-for="(parrafo, i) in lenguages[idioma].faqs.pregunta4.parrafos"
          :key="i"
        >
          {{ parrafo }}
        </p>
      </div>

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta5.titulo }}
        </h2>
        <p
          v-for="(parrafo, i) in lenguages[idioma].faqs.pregunta5.parrafos"
          :key="i"
        >
          {{ parrafo }}
        </p>
      </div>

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta6.titulo }}
        </h2>
        <p
          v-for="(parrafo, i) in lenguages[idioma].faqs.pregunta6.parrafos"
          :key="i"
        >
          {{ parrafo }}
        </p>
      </div>

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta7.titulo }}
        </h2>
        <p>
          {{ lenguages[idioma].faqs.pregunta7.parrafos[0] }}
        </p>
        <ul>
          <li
            v-for="(punto, i) in lenguages[idioma].faqs.pregunta7.puntos"
            :key="i"
          >
            {{ punto }}
          </li>
        </ul>
        <p>
          {{ lenguages[idioma].faqs.pregunta7.parrafos[1] }}
        </p>
      </div>

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta8.titulo }}
        </h2>

        <p
          v-for="(parrafo, i) in lenguages[idioma].faqs.pregunta8.parrafos"
          :key="i"
        >
          {{ parrafo }}
        </p>

        <ul>
          <li
            v-for="(punto, i) in lenguages[idioma].faqs.pregunta8.puntos"
            :key="i"
          >
            {{ punto }}
          </li>
        </ul>
      </div>

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta9.titulo }}
        </h2>
        <p>
          {{ lenguages[idioma].faqs.pregunta9.parrafos[0] }}
        </p>

        <p>
          {{ lenguages[idioma].faqs.pregunta9.parrafos[1] }}
        </p>

        <ul>
         <li
            v-for="(punto, i) in lenguages[idioma].faqs.pregunta9.puntos1"
            :key="i"
          >
            {{ punto }}
          </li>
        </ul>

        <p>
         {{ lenguages[idioma].faqs.pregunta9.parrafos[2] }}
        </p>

        <ul>
          <li
            v-for="(punto, i) in lenguages[idioma].faqs.pregunta9.puntos2"
            :key="i"
          >
            {{ punto }}
          </li>
        </ul>
      </div>

      <div>
        <h2>
          <v-icon color="#645dc7">mdi-frequently-asked-questions</v-icon>
          {{ lenguages[idioma].faqs.pregunta10.titulo }}
        </h2>
        <p>
          {{ lenguages[idioma].faqs.pregunta10.parrafos[0] }}
        </p>
        <ul>
          <li
            v-for="(punto, i) in lenguages[idioma].faqs.pregunta10.puntos"
            :key="i"
          >
            {{ punto }}
          </li>
        </ul>

        <p>
                    {{ lenguages[idioma].faqs.pregunta10.parrafos[1] }}

        </p>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FAQ",
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>

<style lang="scss" scoped>
i {
  color: #645dc7;
}
b {
  font-weight: bold;
}
p {
  margin-bottom: 1px;
}
h2 {
  margin: 10px 0px;
}

ul {
  list-style: initial;
  line-height: 30px;
  margin-bottom: 10px;
}
#FAQ,
.faq {
  background: $main-white;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 95px;
  &__terminosYCondiciones {
    p {
      font-size: 12px;
    }
  }
  &__btnBottom {
    position: fixed;
    bottom: 0;
  }
}

.titulo {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 16px;
}

p {
  padding-bottom: 16px;
}
a {
  color: $main-purple !important;
}

@media (min-width: 960px) {
  #FAQ,
  .faq {
    background-color: $gray-background;
    padding-top: 100px;
    &__terminosYCondiciones {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      background: $main-white;
      p {
        font-size: 18px;
      }
    }
  }
}
</style>
