<template>
  <div>
    <section class="col-12 col-sm-10 mx-auto aerodromos pa-8">
      <h1 class="text-center py-4">Aeródromos de salida</h1>

      <div class="nombres pb-4">
        <li
          @click="setZoomAndView(i)"
          v-for="(nombre, i) in nombresDeLosAerodromos"
          :key="i"
        >
          {{ nombre }}
        </li>
      </div>
      <GMapsConAerodromos :aerodromos="aerodromos" ref="map" id="map" />

      <div class="vista-general pt-8">
        <v-btn @click="resetView">Vista General</v-btn>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

import GMapsConAerodromos from "@/components/GMapsConAerodromos.vue";

export default {
  name: "Aeródromos",

  components: {
    GMapsConAerodromos,
  },
  methods: {
    setZoomAndView(i) {
      this.$refs.map.setZoomAndView(i);
      setTimeout(() => {
        location.href = "#map";
      }, 50);
    },
    resetView() {
      this.$refs.map.resetView();
    },
  },
  computed: {
    ...mapState("Aerodromos", ["aerodromos"]),
    nombresDeLosAerodromos() {
      return this.aerodromos.map((a) => a.data.nombre);
    },
  },
};
</script>

<style lang="scss" scoped>
.aerodromos {
  background-color: $gray-background;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  margin-top: 90px;
  @media (min-width: 600px) {
    margin-top: 150px;
  }
}

.nombres {
  display: grid;
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

li {
  border: solid 1px gray;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms;
  text-align: center;
  background: lightgray;
  font-size: 20px;
  &:hover {
    background: white;
    font-weight: bold;
  }
}

.vista-general {
  display: flex;
  justify-content: center;
}
</style>
