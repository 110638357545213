<template>
  <v-container class="seguridad mx-auto">
    <v-row no-gutters class="pb-15">
      <v-col cols="12" md="6">
        <h1>
          {{
            lenguages[idioma].seguridadOperacional.titulo1 ||
              "POLÍTICA DE SEGURIDAD OPERACIONAL"
          }}

          <hr />
        </h1>
        <p>
          {{ lenguages[idioma].seguridadOperacional.descripcion1 }}
        </p>

        <ul class="py-5">
          <li
            v-for="(punto, i) in lenguages[idioma].seguridadOperacional
              .puntos1_1"
            :key="i"
          >
            <v-icon>mdi-arrow-right</v-icon> {{ punto }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center align-start">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fseguridad%2Ftecnico-ok.jpg?alt=media&token=ba6aae31-a3f2-49cc-8203-cdfc43cc3528"
          alt="técnico aeronautico"
          class="imgRounded"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="pb-15">
      <v-col cols="12" md="6" class="d-flex justify-center align-start pb-7">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fseguridad%2Ftecnico-2-ok.jpg?alt=media&token=a70d5341-67eb-4b6b-90e2-8165104aa358"
          alt="técnico aeronautico"
        />
      </v-col>
      <v-col cols="12" md="6">
        <p class="pb-6">
          {{ lenguages[idioma].seguridadOperacional.descripcion2 }}
        </p>

        <v-row no-gutters class="py-5">
          <v-col cols="12" md="6">
            <p
              class="py-2"
              v-for="(punto, i) in lenguages[idioma].seguridadOperacional
                .puntos1_2"
              :key="i"
            >
              <v-icon>mdi-check-circle</v-icon> {{ punto }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Seguridad",
  title() {
    return `Seguridad operacional - HummingBird`;
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>

<style lang="scss" scoped>
.seguridad {
  padding-top: 150px;
  padding-bottom: 100px;
  img {
    max-width: 90%;
  }
  h1 {
    font-weight: 700;
    font-size: 25px;
    padding-bottom: 10px;
    hr {
      border-bottom: 4px solid #000;
      width: 30%;
    }
  }
  .imgRounded {
    border-radius: 50%;
  }

  ul {
    list-style-type: none;
    list-style-position: outside;
  }
  li {
    padding: 5px 0;
  }
}

@media (min-width: 960px) {
  h1 {
    font-size: 30px;
  }
}
</style>
