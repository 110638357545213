<template>
  <div id="app">
    <GmapMap
      :center="view"
      :zoom="zoom"
      style="width: 100%; height: 600px"
      :mapOptions="mapOptions"
      map-type-id="hybrid"
    >
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      />

      <GmapMarker
        ref="myMarker"
        v-for="({ data: m, id }, i) in aerodromos"
        :key="id"
        :position="m.position"
        :clickable="true"
        @click="setZoomAndView(i)"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "GMapsConAerodromos",
  props: {
    aerodromos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      view: { lat: -33.91507127519754, lng: -71.64892332946303 },
      zoom: 7,
      mapOptions: {
        zoomControl: true,
        fullscreenControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
      },
      infoWindowPos: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -40,
        },
      },
      infoWinOpen: false,
    };
  },
  methods: {
    toggleInfoWindow({ position, nombre, direccion }, idx) {
      console.log(nombre);
      this.infoWindowPos = position;

      this.infoWinOpen = true;

      setTimeout(() => {
        const contentInfoWindowElement = document.querySelector(
          ".gm-style-iw-d"
        );
        contentInfoWindowElement.innerHTML = `
            <p class="font-weight-bold"> ${nombre} </p>
            <a target="_blank" href="http://maps.google.com?q=${direccion}"> ${direccion} </a>
        `;
      }, 1);
    },

    setZoomAndView(index) {
      const {
        data: { position },
      } = this.aerodromos[index];
      this.view = position;
      this.zoom = 20;
      this.toggleInfoWindow(this.aerodromos[index].data, index);
    },

    resetView() {
      this.view = { lat: -33.91507127519754, lng: -71.64892332946303 };
      this.zoom = 7;
      this.infoWinOpen = false;
    },
  },
};
</script>
