<template>
  <div>
    <v-btn color="#574dad" class="white--text" @click="openModalNewVenta"
      >Agregar Venta +
    </v-btn>
    <v-container>
      <v-data-table
        :options="{ itemsPerPage: 5 }"
        :sort-by="['data.date']"
        :sort-desc="[true, false]"
        :headers="headers"
        :items="ventas"
      >
        <template v-slot:item.acciones="{ item }">
          <a :href="`/admin/ventas/${item.id}`">
            <Boton texto="Ver Detalle" height="47px" />
          </a>
        </template>
      </v-data-table>
    </v-container>
    <router-view></router-view>
    <v-dialog v-model="modalNewVenta">
      <v-card width="1250">
        <v-card-title
          class="white--text lighten-1 d-flex justify-space-between"
          style="background-color: #574dad"
        >
          <span>Ingresar venta manualmente</span>
          <span
            v-show="newVenta.experienciaAMedida"
            :style="{ color: colors.green }"
            >Experiencia a medida</span
          >
        </v-card-title>

        <v-card-text class="pa-6">
          <v-row class="align-center">
            <v-col cols="3" class="py-0">
              <v-switch
                class="py-0"
                v-model="newVenta.experienciaAMedida"
                label="Experiencia a medida"
                :color="colors.mainPurple"
              ></v-switch>
            </v-col>
            <v-col v-show="newVenta.experienciaAMedida" cols="3">
              <v-text-field
                v-model="newVenta.experienciaAMedidaDetails.nombre"
                label="Destino de la experiencia"
                prepend-icon="mdi-rename"
                :color="colors.mainPurple"
              ></v-text-field>
            </v-col>
            <v-col v-show="newVenta.experienciaAMedida" cols="3">
              <v-text-field
                v-model="newVenta.experienciaAMedidaDetails.duracion"
                label="Duración"
                prepend-icon="mdi-clock-time-two-outline"
                :color="colors.mainPurple"
                type="time"
              ></v-text-field>
            </v-col>
            <v-col v-show="newVenta.experienciaAMedida" cols="3">
              <v-text-field
                v-model="newVenta.experienciaAMedidaDetails.detalles"
                label="Detalles"
                prepend-icon="mdi-clipboard-text-outline"
                :color="colors.mainPurple"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-menu
                v-model="datePickerShow"
                :close-on-content-click="false"
                min-width="auto"
                :nudge-right="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newVenta.fecha"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :color="colors.mainPurple"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newVenta.fecha"
                  @input="datePickerShow = false"
                  :color="colors.mainPurple"
                  max-width="auto"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :color="colors.mainPurple"
                v-model="newVenta.experiencia"
                :disabled="newVenta.experienciaAMedida"
                :items="experiencias"
                label="Experiencia"
                prepend-icon="mdi-send-outline"
                no-data-text="No se encuentra ninguna experiencia con ese nombre"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-select
                :color="colors.mainPurple"
                :items="formasDePago"
                prepend-icon="mdi-handshake-outline"
                v-model="newVenta.formaDePago"
                label="Forma de pago"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="newVenta.monto"
                label="Monto pagado"
                prepend-icon="mdi-cash-multiple"
                :color="colors.mainPurple"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-6">
            <v-col cols="3">
              <v-text-field
                filled
                prepend-icon="mdi-account"
                v-model="newPasajero.nombre"
                color="#574dad"
                label="Nombre y apellido"
              >
              </v-text-field>
              <v-text-field
                filled
                prepend-icon="mdi-card-account-details-outline"
                v-model="newPasajero.rut"
                color="#574dad"
                label="RUT"
              >
              </v-text-field>
              <v-text-field
                filled
                prepend-icon="mdi-card-account-details-outline"
                v-model="newPasajero.pasaporte"
                color="#574dad"
                label="Pasaporte"
              >
              </v-text-field>
              <v-text-field
                filled
                prepend-icon="mdi-map-marker"
                v-model="newPasajero.pais"
                color="#574dad"
                label="País de emisión del documento"
              >
              </v-text-field>
              <v-text-field
                filled
                prepend-icon="mdi-phone"
                v-model="newPasajero.telefono"
                color="#574dad"
                label="Teléfono"
              >
              </v-text-field>
              <v-text-field
                filled
                prepend-icon="mdi-email"
                v-model="newPasajero.email"
                color="#574dad"
                label="Correo"
              ></v-text-field>
              <v-text-field
                filled
                prepend-icon="mdi-weight-kilogram"
                v-model="newPasajero.peso"
                color="#574dad"
                label="Peso"
              >
              </v-text-field>

              <v-btn
                class="white--text ml-8"
                @click="agregarPasajero"
                color="#574dad"
              >
                Agregar pasajero
              </v-btn>
            </v-col>
            <v-col cols="9">
              <v-data-table
                :headers="headersNuevaVenta"
                :items="newVenta.pasajeros"
                hide-default-footer
                class="elevation-1"
                no-data-text="Aún no se han agregado pasajeros"
              >
                <template v-slot:item.acciones="{ item, index }">
                  <v-btn icon color="red" @click="eliminarPasajero(index)">
                    <v-icon>mdi-close-thick</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.comprador="{ item, index }">
                  <v-checkbox
                    :disabled="
                      compradorSelectedIndex !== -1 &&
                        compradorSelectedIndex !== index
                    "
                    :indeterminate="
                      compradorSelectedIndex !== -1 &&
                        compradorSelectedIndex !== index
                    "
                    v-model="item.comprador"
                    :color="colors.mainPurple"
                    :value="index"
                  ></v-checkbox>
                </template>
              </v-data-table>

              <div class="datos-facturacion">
                <h3 class="mt-6">
                  Datos de facturación
                </h3>
                <v-radio-group
                  v-model="newVenta.datosDeFacturacion.tipoDeUsuario"
                >
                  <div class="d-flex align-center">
                    <v-radio
                      color="#645dc7"
                      :label="`Empresa`"
                      value="empresa"
                      class="mb-0 mr-3"
                    ></v-radio>
                    <v-radio
                      color="#645dc7"
                      :label="`Persona`"
                      value="persona"
                      class="mb-0"
                    ></v-radio>
                  </div>
                </v-radio-group>
                <v-row>
                  <v-col class="py-0">
                    <h3>
                      Nombre o Razón social
                    </h3>
                    <v-text-field
                      outlined
                      dense
                      color="#645dc7"
                      v-model="newVenta.datosDeFacturacion.razonSocial"
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0">
                    <h3>
                      RUT
                    </h3>
                    <v-text-field
                      outlined
                      dense
                      color="#645dc7"
                      v-model="newVenta.datosDeFacturacion.rut"
                      prepend-inner-icon="mdi-card-account-details"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <h3>
                      Dirección
                    </h3>
                    <v-text-field
                      outlined
                      dense
                      color="#645dc7"
                      v-model="newVenta.datosDeFacturacion.direccion"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0">
                    <h3>
                      Giro comercial
                    </h3>
                    <v-text-field
                      outlined
                      dense
                      color="#645dc7"
                      v-model="newVenta.datosDeFacturacion.giro"
                      prepend-inner-icon="mdi-format-list-group"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="white--text" @click="registrarVenta" color="#574dad">
            Registrar venta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import firebase from "firebase";
import moment from "moment";

export default {
  name: "Ventas",
  components: {
    Boton,
  },
  data() {
    return {
      formasDePago: [
        "Link de pago de FLOW",
        "Transferencia",
        "Cuponatic",
        "Depósito",
        "Varios/Combinado",
        "Efectivo",
        "Otro",
      ],
      datePickerShow: false,
      newPasajero: {},
      modalNewVenta: false,
      headersNuevaVenta: [
        { text: "Nombre", value: "nombre" },
        { text: "RUT", value: "rut" },
        { text: "Pasaporte", value: "pasaporte" },
        { text: "Pais", value: "pais" },
        { text: "Teléfono", value: "telefono" },
        { text: "Peso", value: "peso" },
        { text: "Comprador", value: "comprador" },
        { text: "", value: "acciones" },
      ],
      newVenta: {
        experienciaAMedida: false,
        experienciaAMedidaDetails: {
          nombre: "",
          duracion: "",
          detalles: "",
        },
        monto: "",
        formaDePago: "",
        pasajeros: [
          {
            nombre: "Juan Manuel",
            pais: "Chile",
            pasaporte: "",
            peso: "70",
            rut: "22158292-6",
            telefono: 569,
          },

          {
            nombre: "Juan Manuel",
            pais: "Chile",
            pasaporte: "",
            peso: "70",
            rut: "22158292-6",
            telefono: 569,
          },
        ],
        fecha: "",
        experiencia: {},
        datosDeFacturacion: {},
      },
      idCompra: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Fecha", value: "data.date" },
        { text: "Nombre", value: "data.details.ida.data.destino" },
        { text: "Comprador", value: "data.userData.displayName" },
        { text: "Acciones", value: "acciones" },
      ],
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Ventas", ["ventas"]),
    experiencias() {
      return this.$store.state.Experiencias.experiencias.map((e) => ({
        value: { id: e.id, name: e.data.destino },
        text: e.data.destino,
      }));
    },
    compradorSelectedIndex() {
      return this.newVenta.pasajeros.findIndex((p) => p.comprador);
    },
  },
  methods: {
    registrarVenta() {
      const { newVenta } = this;
      const payload = {};
      payload.date = moment().format("YYYY-MM-DD HH:mm");
      payload.details = {};
      payload.details.datosDeFacturacion = newVenta.datosDeFacturacion;
      const esUnaExperienciaAMedida = newVenta.experienciaAMedida;
      payload.details.ida = {
        data: {
          destino: esUnaExperienciaAMedida
            ? newVenta.experienciaAMedidaDetails.nombre
            : newVenta.experiencia.name,
        },
        id: newVenta.experiencia.id || "",
        experienciaAMedidaDetails: newVenta.experienciaAMedidaDetails,
      };
      payload.details.info = {
        pasajeros: newVenta.pasajeros.length,
        tipoDeViaje: "Experiencia",
        tipoDeVuelo: "Solo ida",
      };
      payload.details.listaPasajeros = newVenta.pasajeros;
      payload.details.medioPago = newVenta.formaDePago;
      payload.details.total = newVenta.monto;
      payload.fecha = newVenta.fecha;
      payload.subject = "Ingreso de Venta Manual";
      const comprador =
        newVenta.pasajeros.find((p) => p.comprador) || newVenta.pasajeros[0];
      payload.userData = { ...comprador, displayName: comprador.nombre };
      firebase
        .firestore()
        .collection("ventas")
        .add(payload);

      this.modalNewVenta = false;
    },
    goToDetail() {
      window.location.assign(`/admin/ventas/${this.idCompra.trim()}`);
      this.idCompra = "";
    },
    openModalNewVenta() {
      this.modalNewVenta = true;
    },

    agregarPasajero() {
      const { newPasajero } = this;
      this.newVenta.pasajeros.push(newPasajero);
      this.newPasajero = {};
    },

    eliminarPasajero(index) {
      this.newVenta.pasajeros.splice(index, 1);
    },
  },
  title() {
    return `Ver detalle de venta - HummingBird`;
  },
};
</script>
