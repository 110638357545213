<template>
  <div id="NewsLetter" class="py-3">
    <section class="container">
      <h1>
        {{
          lenguages[idioma].newsLetter.titulo ||
            "¿Quieres enterarte de todas las noticias y ofertas de HummingBird?"
        }}
      </h1>
      <h3>
        {{
          lenguages[idioma].newsLetter.subtitulo ||
            "¡Déjanos tu correo electrónico!"
        }}
      </h3>

      <div class="mt-5">
        <h3>Email:</h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          :placeholder="lenguages[idioma].newsLetter.placeholder"
          v-model="email"
        ></v-text-field>

        <div @click="addNewsLetter" class="d-flex">
          <Boton :texto="lenguages[idioma].newsLetter.enviar" class="btn" />
          <v-progress-circular
            indeterminate
            color="#645dc7"
            class="ml-3"
            v-show="loading"
          ></v-progress-circular>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import firebase from "firebase";
import axios from "axios";
const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default {
  name: "News Letter",
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async addNewsLetter() {
      try {
        this.loading = true;
        const { email } = this;
        if (!email) throw { message: "Debe escribir un correo electrónico" };
        if (!expEmail.test(email))
          throw { message: "El formato del correo electrónico es incorrecto" };

        await firebase
          .firestore()
          .collection("subscriptores")
          .add({ email });

        const url =
          "https://us-central1-hummingbird-453db.cloudfunctions.net/mailing/newsletter";
        await axios.post(url, { email });

        this.$toast.open({
          message: "¡Mensaje enviado!",
          position: "bottom",
          duration: 3000,
        });

        this.email = "";
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message, {
          position: "bottom",
          duration: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    Boton,
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>
